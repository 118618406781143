
import React, { useState } from "react";
import { createRoot } from 'react-dom/client';

import i18n from "i18next";
import { useTranslation } from "react-i18next";

import RootData from './RootData';
import CloseSelect from './CloseSelect';
import LoadSelect from './LoadSelect';
import updateFrontendContent from './update.js';


export default function MainMenu() {
    const [language, setLanguage] = useState('en');
    const { t } = useTranslation();

    const onLanguageMenu = (event) => {
        event.preventDefault();
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
    }
    const onLoadMenu = (event) => {
        event.preventDefault();
        const server = window.server;
        server.updatePackageItems ((data) => {
            if (!RootData.loadSelect) {
                const container = document.getElementById('loadSelect');
                RootData.loadSelect = createRoot(container);
            }
            RootData.loadSelect.render(
              <React.StrictMode>
                <LoadSelect server={server} items={data} />
              </React.StrictMode>
            );
        });
        window.onLoadMenu(() => {
            const name = document.getElementById("loadSelectData").value;
            if (name === "")
                return;
            server.loadRemoteProject(name, () => {
                updateFrontendContent (server);
            });
        });
    }
    const onCloseMenu = (event) => {
        event.preventDefault();
        const server = window.server;
        server.updateProjectList ((data) => {
            if (!RootData.closeSelect) {
                const container = document.getElementById('closeSelect');
                RootData.closeSelect = createRoot(container);
            }
            RootData.closeSelect.render(
                <React.StrictMode>
                    <CloseSelect server={server} items={data} />
                </React.StrictMode>
            );
        });
        window.onCloseMenu(() => {
            const name = document.getElementById("closeSelectData").value;
            if (name === "")
                return;
            server.closeProject(name, () => {
                updateFrontendContent (server);
            });
        });
    }

    const importProject = (event) => {
        event.preventDefault();
        window.importProject(() => {
            updateFrontendContent (window.server);
        });
    }
    const onAddLocationMenu = (event) => {
        event.preventDefault();
        window.onAddLocationMenu();
            const name = document.getElementById("addLocationData").value;
            if (name === "")
                return;
            const server = window.server;
            server.closeProject(name, () => {
                updateFrontendContent (server);
            });
    }

    const onLicenseMenu = (event) => {
        event.preventDefault();
        window.onLicenseMenu();
    }
    const onAboutMenu = (event) => {
        event.preventDefault();
        window.onAboutMenu();
    }
    const onShutdownMenu = (event) => {
        event.preventDefault();
        window.onShutdownMenu(() => {
            window.server.shutdown ();
        });
    }

    return (
        <aside className="ui top fixed menu">
            <div className="ui primary dropdown item">
                {t("menu.Project")}
                <i className="dropdown icon"></i>
                <div className="menu">
                    <button className="item" onClick={onLoadMenu}><i className="folder open outline icon"></i> {t("menu.Load")} </button>
                    <button className="item" onClick={onCloseMenu}><i className="window close outline icon"></i> {t("menu.Close")} </button>
                    <button className="item" onClick={importProject}><i className="upload icon"></i> {t("menu.Import")} </button>
                    <button className="disabled item" onClick={onAddLocationMenu}><i className="plus square icon"></i> {t("menu.AddLocation")} </button>
                </div>
            </div>
            <div className="right menu">
                <div className="header item">Project: MODEMA {t("menu.tp")} 2024.02.01. (v0.90.0)</div>
                <div className="item" onClick={onShutdownMenu}><i className="red power off icon"></i></div>
                <div className="ui dropdown item">
                    Language ({i18n.resolvedLanguage})
                    <i className="dropdown icon"></i>
                    <div className="menu">
                        <button className="item" value="en" onClick={onLanguageMenu}><i className="united kingdom flag"></i> English </button>
                        <button className="item" value="hu" onClick={onLanguageMenu}><i className="hungary flag"></i> Magyar </button>
                    </div>
                </div>
                <div className="ui dropdown item">
                    {t("menu.Help")}
                    <i className="dropdown icon"></i>
                    <div className="menu">
                        <button className="item" onClick={onLicenseMenu}> {t("menu.License")} </button>
                        <button className="item" onClick={onAboutMenu}> {t("menu.About")} </button>
                    </div>
                </div>
            </div>
        </aside>
    );
}

