
import React from 'react';
import { createRoot } from 'react-dom/client';
import { withTranslation } from 'react-i18next';

import RootData from './RootData';
import OntologySelect from './OntologySelect';
import OntologyTable from './OntologyTable';


class OntologyLayoutClass extends React.Component {
    ontologyTable = null;

    constructor(props) {
        super(props);
        this.ontologyClear = this.ontologyClear.bind(this);
        this.ontologySubmit = this.ontologySubmit.bind(this);
    }

    ontologyClear() {
        var empty = [];
        const server = this.props.server;
        this.ontologyTable.render(
            <React.StrictMode>
                <OntologyTable server={server} items={empty} />
            </React.StrictMode>
        );
    }

    ontologySubmit() {
        var ontologyName = document.getElementById("ontologyName");
        if (!ontologyName)
            return;
        var selectorType = document.getElementById("selectorType");
        if (!selectorType)
            return;
        const name = ontologyName.value;
        const type = selectorType.value;
        const format = document.getElementById("ontologyFormat").value;
        const server = this.props.server;
        server.ontologyQuery (name, type, format, (data) => {
            if (!this.ontologyTable) {
                const container = document.getElementById('ontologyTable');
                this.ontologyTable = createRoot(container);
            }
            this.ontologyTable.render(
                <React.StrictMode>
                    <OntologyTable server={server} items={data} />
                </React.StrictMode>
            );
        });
    }

    componentDidMount() {
        const server = this.props.server;
        if (!RootData.ontologySelect) {
            const container = document.getElementById('ontologySelect');
            RootData.ontologySelect = createRoot(container);
        }
        var empty = { names: [] };
        RootData.ontologySelect.render(
          <React.StrictMode>
            <OntologySelect server={server} items={empty} />
          </React.StrictMode>
        );
    }

    render() {
        const { t } = this.props;
        return (
            <div className="ui tab segment" data-tab="ontologyLayout">
                <div className="row">
                    <div className="ui labeled input">
                        <div className="ui label"> {t('ontology.Selector')}: </div>
                            <select id="selectorType" className="ui select">
                                <option value="resources"> {t('ontology.Resources')} </option>
                                <option value="packages"> {t('ontology.Packages')} </option>
                                <option value="openedProjects"> {t('ontology.OpenedProjects')} </option>
                                <option value="representations"> {t('ontology.Representations')} </option>
                                <option value="workspaces"> {t('ontology.Workspaces')} </option>
                            </select>
                        </div>
                        <div className="ui labeled input queryPadding">
                            <div className="ui label"> {t('ontology.Repository')}:</div>
                            <div id="ontologySelect"></div>
                        </div>
                        <div className="ui labeled input queryPadding">
                            <div className="ui label"> {t('ontology.Format')}:</div>
                            <select id="ontologyFormat" className="ui select">
                                <option value="modema">Modema/Lang</option>
                                <option value="turtle">Turtle</option>
                                <option value="rdf">RDF</option>
                            </select>
                        </div>
                        <button className="ui green button" type="submit" onClick={this.ontologySubmit}> {t('ontology.Query')} </button>
                        <button className="ui orange button" type="submit" onClick={this.ontologyClear}> {t('ontology.Clear')} </button>
                    </div>
                <div id="ontologyTable"></div>
            </div>
        );
    }
}

const OntologyLayout = withTranslation()(OntologyLayoutClass);
export default OntologyLayout;
