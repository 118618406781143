
import React from 'react';
import { withTranslation } from 'react-i18next';
import i18n from "i18next";
import {basicSetup} from "codemirror"
import {EditorState, Compartment} from "@codemirror/state"
import modema from './grammar/modema_language.js';


class ExampleListClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            language: "",
            isLoaded: false,
            items: []
        };
        this.onExampleClick = this.onExampleClick.bind(this);
    }

    onExampleClick (fileName, codeLang) {
        fetch("/" + fileName)
            .then(res => res.text())
            .then(
                (result) => {
                    document.getElementById("console").innerHTML = "";
                    let language = new Compartment();
                    this.props.editor.dispatch({changes: {from: 0, to: this.props.editor.state.doc.length, insert: result}});
                    if ((codeLang === 'modema-runtime') || (codeLang === 'modema-full')) {
                        document.getElementById("codeLang").value = codeLang;
                        window.setCodeLang(codeLang);
                    }
                    else
                        console.log ("Unknown code language: " + codeLang);
                },
                (error) => {
                    document.getElementById("console").innerHTML = "<fetch error:>" + error;
                    this.props.editor.dispatch({changes: {from: 0, to: this.props.editor.state.doc.length, insert: "<error>"}});
            }
        )
    }

    updateList () {
        var path = "/examples";
        if (i18n.resolvedLanguage === "hu") {
            path = "/examples_hu";
        }
        fetch(path+"/examples.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        language: i18n.resolvedLanguage,
                        items: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: false,
                        language: i18n.resolvedLanguage,
                        items: [],
                        error
                    });
                }
            )
    }

    componentDidMount() {
        this.updateList();
    }

    componentDidUpdate() {
        const language = this.state.language;
        if (i18n.resolvedLanguage !== language)
            this.updateList();
    }

    render() {
        const { t } = this.props;
        const { error, isLoaded, items } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div> {t('generic.Loading')} </div>;
        } else {
            const elements = items.map((item) =>
                <div key={item.id} className="item" onClick={() => this.onExampleClick(item.file, item.language)}>
                    <i className="code icon"></i>
                    <div className="content">
                        <div className="header">{item.name}</div>
                    </div>
                </div>
            );
            return (
                <div className="ui small selection list">
                    {elements}
                </div>
              );
        }
    }
}

const ExampleList = withTranslation()(ExampleListClass);
export default ExampleList;

