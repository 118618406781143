
import React from 'react';
import { useTranslation } from "react-i18next";


const AboutDialog = () => {
    const { t } = useTranslation();
    return (
        <div className="ui small modal" id="about">
            <div className="header"> {t('dialog.About')} </div>
            <div className="content">
                <p>Copyright (C) 2024, Egry Gábor Szilárd &lt;info@modema.hu&gt;</p>
                <p>All Rights reserved! / Minden jog fenntartva!</p>
            </div>
            <div className="actions">
                <div className="ui ok button"> {t('dialog.Close')} </div>
            </div>
        </div>
    );
};

export default AboutDialog;