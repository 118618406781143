
export const ENGLISH = {
    side:  {
        "Welcome": "Welcome",
        "Examples": "Examples",
        "Projects": "Projects",
        "Content": "Content",
        "Targets": "Targets",
        "Ontology": "Ontology",
        "Query": "Query",
        "Repositories": "Repositories",
        "Documentation": "Documentation",
        "EngineRoom": "Engine room"
    },
    menu: {
        "Project": "Project",
        "Load": "Load",
        "Close": "Close",
        "Import": "Import",
        "AddLocation": "Add location",
        "Help": "Help",
        "License": "License",
        "About": "About",
        "tp": "technology preview"
    },
    engine: {
        "Values": "Values",
        "Paths": "Paths",
        "Context": "Context",
        "GlobalRefresh": "Global refresh"
    },
    example: {
        "Args": "Arguments",
        "Execute": "Execute",
        "ImportFile": "Import file",
        "placeholder": "Example code... (Press button for fullscreen)",
        "Language": "Language",
        "LangRuntime": "Runtime",
        "LangFull": "Full"
    },
    dialog: {
        "Ok": "Ok",
        "Cancel": "Cancel",
        "Yes": "Yes",
        "No": "No",
        "Close": "Close",
        "About": "About",
        "AddLocation": "Add location",
        "CloseProject": "Close project",
        "License": "License",
        "LoadProject": "Load project",
        "Loading": "Loading core..",
        "Shutdown": "Shutdown",
        "Sure": "Are you sure?",
        "testing": "Testing purpose only on current site.",
        "store": "This web page does not store or collect any data."
    },
    content: {
        "select": "Select project and content first."
    },
    doc: {
        "manual": "Language reference manual",
        "package": "Package documentation (Work In Progress)"
    },
    project: {
        "select": "Select project first.",
        "PackageName": "Package name",
        "CloseRemote": "Close remote project",
        "LoadRemote": "Load remote project",
        "Close": "Close",
        "Load": "Load",
        "Name": "Name",
        "Description": "Description",
        "ID": "ID",
        "Type": "Type",
        "Path": "Path",
        "License": "License",
        "Version": "Version",
        "Timestamp": "Timestamp",
        "Provides": "Provides",
        "Requires": "Requires",
        "ProjectDescripion": "Project descripion.",
        "Projects": "Projects",
        "Packages": "Packages",
        "Resources": "Resources",
        "Remote": "Remote"
    },
    ontology: {
        "Resources": "Resources",
        "Packages": "Packages",
        "OpenedProjects": "Opened projects",
        "Representations": "Representations",
        "Workspaces": "Workspaces",
        "Repository": "Repository",
        "Format": "Format",
        "Query": "Query",
        "Clear": "Clear",
        "Selector": "Selector"
    },
    query: {
        "NodeType": "Node type",
        "Resource": "Resource",
        "Class": "Class",
        "Function": "Function",
        "Representation": "Representation",
        "Transform": "Transform",
        "Repository": "Repository",
        "Query": "Query",
        "Clear": "Clear",
        "Name": "Name",
        "Type": "Type",
        "Package": "Package"
    },
    repo: {
        "select": "Select repository first.",
        "Name": "Name",
        "ContentSize": "Content size",
        "ImportedPackages": "Imported packages",
        "Namespaces": "Namespaces",
        "Workspaces": "Workspaces"
    },
    target: {
        "select": "Select project and target first.",
        "empty": "empty",
        "Targets": "Targets",
        "DynamicTargets": "Dynamic targets",
        "Documentation": "Documentation",
        "InfoTarget": "Informations",
        "Tests": "Tests",
        "Clean": "Clean"
    },
    welcome: {
        "title": "Modema/System technology preview",
        "intro": "Developer environment based on knowledge representation.",
        "About": "About",
        "about": "About.",
        "Usage": "Usage",
        "usage": "Simple code examples are in the examples side menu. Complex examples are in the projects side menu or import from the top menu. The other menus are for handling the loaded projects."
    },
    generic: {
        "Loading": "Loading...",
        "All": "All"
    }
};

