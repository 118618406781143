
import React from 'react';
import { useTranslation } from "react-i18next";


const DocLayout = () => {
    const { t } = useTranslation();
    return (
        <div className="ui tab segment" data-tab="docLayout">
            <div className="ui segment">
                <a href="https://docs.modema.hu/#kezikonyv" target="_blank" rel="noopener noreferrer">{t('doc.manual')}</a>
            </div>
            <h3>{t('doc.package')}:</h3>
            <div className="ui small list">
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.modema.hu/library/Modema_library_doc.html" target="_blank" rel="noopener noreferrer">Modema</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.modema.hu/library/ModemaDoc_library_doc.html" target="_blank" rel="noopener noreferrer">ModemaDoc</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.modema.hu/library/ModemaEvent_library_doc.html" target="_blank" rel="noopener noreferrer">ModemaEvent</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.modema.hu/library/ModemaExecute_library_doc.html" target="_blank" rel="noopener noreferrer">ModemaExecute</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.modema.hu/library/ModemaFileSystem_library_doc.html" target="_blank" rel="noopener noreferrer">ModemaFileSystem</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.modema.hu/library/ModemaOntology_library_doc.html" target="_blank" rel="noopener noreferrer">ModemaOntology</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.modema.hu/library/ModemaProject_library_doc.html" target="_blank" rel="noopener noreferrer">ModemaProject</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.modema.hu/library/ModemaString_library_doc.html" target="_blank" rel="noopener noreferrer">ModemaString</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.modema.hu/library/ModemaTesting_library_doc.html" target="_blank" rel="noopener noreferrer">ModemaTesting</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.modema.hu/library/ModemaTransform_library_doc.html" target="_blank" rel="noopener noreferrer">ModemaTransform</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.modema.hu/library/ModemaVersion_library_doc.html" target="_blank" rel="noopener noreferrer">ModemaVersion</a>
                    </div>
                </div>
            </div>
            <h3>Test project archives:</h3>
            <div className="ui small list">
                <div className="item">
                    <i className="archive icon"></i>
                    <div className="content">
                        <a className="header" href="/projects/Hello.zip" target="_blank">'Hello world!' example</a>
                    </div>
                </div>
                <div className="item">
                    <i className="archive icon"></i>
                    <div className="content">
                        <a className="header" href="/projects/Targets.zip" target="_blank">Target example.</a>
                    </div>
                </div>
                <div className="item">
                    <i className="archive icon"></i>
                    <div className="content">
                        <a className="header" href="/projects/Generic.zip" target="_blank">Generic project example.</a>
                    </div>
                </div>
                <div className="item">
                    <i className="archive icon"></i>
                    <div className="content">
                        <a className="header" href="/projects/Package.zip" target="_blank">Package example.</a>
                    </div>
                </div>
                <div className="item">
                    <i className="archive icon"></i>
                    <div className="content">
                        <a className="header" href="/projects/Version.zip" target="_blank">Version example.</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocLayout;
